<script>
  /* COMPONENT DOCUMENT
   * author: liyulong
   * date: 2020/04/10
   * desc: 文档管理
   */

  // import Vue from 'vue';
  import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
  import config from '@/utils/config';
  import { yymmddhhmm } from '@/utils/utils';

  export default {
    name: 'document-management',
    components: {
      nfBreadcrumb
    },
    created() {
      this.getInfoList();
    },

    data() {
      return {
        yymmddhhmm,
        breadList: [
          // { name: '业务协同', path: '/nanFanManagement' },
          { name: '文档管理' }
        ],
        loading: true,
        active_index: '0',
        current_page: 1,
        page_size: 10,
        total: 0,
        tableData: [],
        total_page: 0,  // 总页数
        shareData: [],
        select_ids: []
      };
    },

    methods: {
      /** 下载数据 **/
      async downLoadFile() {
        const api = '/api/file/open/downloadAllFile/' + this.select_ids.join();
        const a = document.createElement('a');

        a.href = config.baseUrl + api;
        a.click();
      },
      /** 删除数据 **/
      async Deleteinfo() {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const data = await this.$fetchData.fetchPost({ fileId: this.select_ids }, '/api/supplementary/document/deleteDocument', 'json');

          if (data.code === '200') {
            this.$message({ type: 'success', message: '删除成功!' });
            this.select_ids = [];
            this.getInfoList();
          } else {
            this.$message({ type: 'error', message: data.message });
          }
        }).catch(() => {});
      },
      /** 我的列表 **/
      async getInfoList(page) {
        const params = {
          pageNum: page || 1,
          pageSize: this.page_size
        };
        this.loading = true;
        const data = await this.$fetchData.fetchPost(params, '/api/supplementary/document/myDocumentList', 'json');

        this.loading = false;
        if (data.code === '200') {
          this.total_page = data.result.total || 0;
          this.tableData = data.result.rows;
        } else {
          this.$message({ type: 'error', message: data.message });
        }
      },
      /** 共享列表 **/
      async getShareList(page) {
        const params = {
          pageNum: page || 1,
          pageSize: this.page_size
        };
        this.loading = true;
        const data = await this.$fetchData.fetchPost(params, '/api/supplementary/document/shareDocumentList', 'json');

        this.loading = false;
        if (data.code === '200') {
          this.total_page = data.result.total || 0;
          this.shareData = data.result.rows;
        } else {
          this.$message({ type: 'error', message: data.message });
        }
      },
      /** 菜单激活回调 */
      handleSelect(key) {
        this.active_index = key;
        this.select_ids = [];
        this.current_page = 1;
        if (this.active_index === '0') {
          this.getInfoList();
        } else {
          this.getShareList();
        }
      },
      /** 上传文档 */
      uploadFile() {
        this.$refs.file_input.click();
      },
      async fileChange(e) {
        console.log(e)
        if (!e.target.value) { return; }
        const upload_types = ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']; // 上传文件的类型
        const file = e.target.files[0];
        const suffix_name = file.name.replace(/.+\./, '').toLowerCase(); // 文件的后缀名

        if (!upload_types.includes(suffix_name)) {
          this.$message({ type: 'error', message: '文件类型不符合!' });
          return;
        }
        if (file.size > 20 * 1024 * 1024) {
          this.$message({ type: 'error', message: '文件的大小已超过20M!' });
          return;
        }
        // 上传到阿里云
        const formdata = new FormData();

        formdata.append('files', file);
        formdata.append('module', 'documentManagement');
        formdata.append('subModule', 'official');
        const api = this.$fetchApi.uploadFile.api;
        const data = await this.$fetchData.fetchPost(formdata, api, 'file');
        if (data.code === '200') {
          this.insertInfo(data.result[0].id);
        }

        e.target.value = '';
      },
      /** 新增 **/
      async insertInfo(a) {
        const res = await this.$fetchData.fetchPost({}, '/api/supplementary/document/addDocument/' + a, 'json');
        if (res.code === '200') {
          this.getInfoList();
          this.$message({ type: 'success', message: '上传成功!' });
        }
      },
      /** 分享 */
      shareDocument(val) {
        if (val.fileStatus === 2) {
          this.$message({ type: 'success', message: '已分享到共享空间!' });
          return;
        }
        this.$confirm('是否将此文件分享到共享空间?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await this.$fetchData.fetchPost({}, '/api/supplementary/document/shareDocument/' + val.fileId, 'json');

          if (res.code === '200') {
            this.$message({ type: 'success', message: '分享成功!' });
            this.$set(val, 'fileStatus', 2);
          } else {
            this.$message({ type: 'error', message: data.message });
          }
        }).catch(() => {});
      },

      /** 页数改变 */
      pageChange(page) {
        if (this.active_index === '0') {
          this.getInfoList(page);
        } else {
          this.getShareList(page);
        }
      },

      /** 表格选择发生变化 */
      selectChange(val) {
        this.select_ids = val.reduce((pre, cur) => {
          pre.push(cur.fileId);
          return pre;
        }, []);
      },
      /** 查看文档 */
      viewDocument(path) {
        if (!path) { return; }
        const suffix_name = path.replace(/.+\./, '').toLowerCase(); // 文件的后缀名

        if (['docx', 'doc', 'xls', 'xlsx', 'pptx'].includes(suffix_name)) {
          window.open('https://view.officeapps.live.com/op/view.aspx?src=' + path);
        } else {
          window.open(path);
        }
      }
    }
  };
</script>

<template>
  <div class="module_documentManagement">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

    <div class="content">
      <el-menu :default-active="active_index" class="el-menu-vertical-demo el_menu" @select="handleSelect">
        <el-menu-item index="0">
          <img :src="require('@/assets/images/document_file.png')" class="folder">
          <span slot="title">我的文档库</span>
        </el-menu-item>
        <el-menu-item index="1">
          <img :src="require('@/assets/images/share_file.png')" class="folder">
          <span slot="title">共享空间</span>
        </el-menu-item>
      </el-menu>
      <div class="menu_content" v-loading="loading">
        <!-- 我的文档库 -->
        <template v-if="active_index === '0'">
          <input type="file" ref="file_input" @change="fileChange($event)" style="display: none;">
          <template v-if="tableData.length > 0">
            <el-button type="primary" icon="el-icon-upload2" size="small" @click="uploadFile">上传文档</el-button>
            <el-button type="primary" size="small" @click="downLoadFile" :disabled="select_ids.length === 0">下载</el-button>
            <el-button type="danger" size="small" @click="Deleteinfo" :disabled="select_ids.length === 0">删除</el-button>
            <el-table :data="tableData" style="width: 100%" key="0" @selection-change="selectChange">
              <el-table-column type="selection" width="55"/>
              <el-table-column label="名称">
                <template slot-scope="scope">
                  <span @click="viewDocument(scope.row.filePath)" class="pointer">{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="size" label="文件大小" width="120">
                <template slot-scope="scope">
                  <span>{{ (scope.row.fileSize / 1024).toFixed(2) + ' KB' }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="时间" width="180" align="center">
                <template slot-scope="scope">
                  <span>{{ yymmddhhmm(new Date(scope.row.createdDate)) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100" align="center">
                <template slot-scope="scope">
                  <i :class="['el-icon-share pointer', { 'active': scope.row.fileStatus === 2 }]"
                     @click="shareDocument(scope.row)"></i>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination :total="total_page" :page-size="page_size" :current-page.sync="current_page"
              @current-change="pageChange" v-if="total_page > 0" background layout="prev, pager, next" />
          </template>

          <div class="tips" v-if="tableData.length === 0 && !loading">
            <img :src="require('@/assets/images/floder.png')">
            <div>
              <p>您暂时还未上传任何文档</p>
              <span>请上传相关文件</span>
            </div>
            <el-button type="primary" size="small" @click="uploadFile">上传文件</el-button>
          </div>
        </template>

        <!-- 共享空间 -->
        <template v-else>
          <template v-if="shareData.length > 0">
            <el-button type="primary" size="small" @click="downLoadFile" :disabled="select_ids.length === 0">下载</el-button>
            <el-table :data="shareData" style="width: 100%" key="1" @selection-change="selectChange">
              <el-table-column type="selection" width="55"/>
              <el-table-column label="名称">
                <template slot-scope="scope">
                  <span @click="viewDocument(scope.row.filePath)" class="pointer">{{ scope.row.name }}</span>
                </template>
              </el-table-column>
                <el-table-column prop="size" label="文件大小" width="120">
                  <template slot-scope="scope">
                    <span>{{ (scope.row.fileSize / 1024).toFixed(2) + ' KB' }}</span>
                  </template>
                </el-table-column>
              <el-table-column prop="realName" label="分享人" width="200" align="center"/>
            </el-table>
            <el-pagination :total="total_page" :page-size="page_size" :current-page.sync="current_page"
              @current-change="pageChange" v-if="total_page > 0" background layout="prev, pager, next" />
          </template>
          
          <div class="tips" v-if="shareData.length === 0 && !loading">
            <img :src="require('@/assets/images/floder.png')">
            <div>
              <p>您暂时还未有共享文件</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .module_documentManagement {
    .content {
      width: 1200px;
      min-height: 600px;
      margin: 0 auto;
      display: flex;

      .el_menu {
        width: 170px;

        .el-menu-item {
          &.is-active {
            background-image: linear-gradient(90deg,
            #C9DCFD 0%,
            #CFDFFD 0%,
            #D0E0FD 34%,
            #D8E5FD 54%,
            #ECF1FD 72%,
            #ffffff 100%),
            linear-gradient(#265ae0, #265ae0);
          }
        }
      }

      .menu_content {
        flex: 1;
        padding: 20px;
        box-sizing: border-box;

        .el-table {
          margin: 20px 0;
        }

        .el-pagination {
          text-align: center;
          margin: 40px 0 20px;
        }
      }

      .pointer {
        cursor: pointer;

        &:hover {
          color: #409EFF;
        }

        &.active {
          color: #409EFF;
        }
      }

      .folder {
        width: 30px;
        height: 30px;
        margin-right: 7px;
      }
    }

    .el-icon-share {
      font-size: 18px;
    }

    .tips {
      text-align: center;
      margin-top: 100px;

      img {
        width: 112px;
        height: 90px;
      }

      div {
        margin: 20px 0;
      }

      p {
        color: #191F2E;
        font-size: 20px;
        margin-bottom: 10px;
      }

      span {
        color: #969EB4;
        font-size: 14px;
      }

      .el-button {
        width: 145px;
      }
    }
  }

</style>
